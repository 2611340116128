@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-sm bg-white text-slate-700;
}
img{
  width: auto;
  max-width: 100%;
}

a, button{
  @apply transition-all;
}

.bg-gradient-coustom.btn{
  @apply before:rounded-t-3xl hover:bg-primary-500;
}
.btn{
  @apply inline-block px-3 py-1 font-semibold transition-all sm:px-7 sm:py-2 rounded-3xl;
}


.banner .slick-prev{
  border-top: solid 2px #000;
  border-left: solid 2px #000;
  @apply z-10 inline-block w-5 h-5 -rotate-45 border-gray-700 opacity-100 left-3;
 
}
.banner .slick-next{
  border-top: solid 2px #000;
  border-right: solid 2px #000;
  @apply inline-block w-5 h-5 rotate-45 border-gray-700 opacity-100 right-3;
}
.banner .slick-arrow::before{
  @apply hidden;
}
.banner .slick-prev, .banner .slick-next{
  top: 40%;
}

.form-control{
  @apply w-full h-10 py-1 font-light text-white bg-transparent border-b border-white rounded-sm outline-none placeholder:text-white/50;
}

textarea.form-control{
  @apply h-auto resize-none;
}
.marketing-section{
  background-image: url('./Assets/Images/bg-image-2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.marketing-box{
  background-image: url('./Assets/Images/logo-bg.png');
  background-position: 90% 20%;
  background-repeat: no-repeat;
  @apply  bg-indigo-500;
}
.contact-section{
  background-image: url('./Assets/Images/bg-image-3.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.Contact-box{
  background-image: url('./Assets/Images/logo-bg.png');
  background-position: 90% 90%;
  background-repeat: no-repeat;
  @apply  bg-indigo-500;
}

.privacy ul, .list-items{
  @apply pt-2 pb-4;
}
.privacy ul li, .list-items li{
  @apply relative pl-6 mb-3 text-sm font-light;
}
.privacy ul li p{
  @apply  mb-0;
}

.privacy ul li p strong, .privacy ul li strong{
  @apply font-semibold;
}

.privacy ul li::before, .list-items li::before{
  background-image: url('./Assets/Images/logo-icon.png');
  background-position: 0 0;
  content: "";
  background-repeat: no-repeat;
  background-size: cover;
  @apply absolute left-0 w-3 h-3 top-1;
 
}
.privacy h1{
  @apply pb-2 text-2xl font-medium;
}

.privacy h2{
  @apply pb-2 text-xl font-medium;
}
.privacy h3{
  @apply pb-2 text-lg font-medium;
}
.privacy p{
  @apply mb-3 font-light;
}





